<template>
  <div>
    <div class="my_calendar_container">
      <div v-if="classId == 2" class="my_calendar_tip">
        <span class="calendar_tips class"> </span>
        <span>當天有家教</span>
      </div>
      <div v-else class="my_calendar_tip">
        <span class="calendar_tips study"> </span>
        <span>當天有預習</span>

        <span class="calendar_tips test_before"> </span>
        <span>當天有前測</span>

        <span class="calendar_tips class"> </span>
        <span>當天有家教</span>

        <span class="calendar_tips homework"> </span>
        <span>當天有作業</span>
      </div>
      <div id="full-clndr" class="clearfix">
        <div class="clndr">
          <div class="clndr-controls">
            <div class="clndr-previous-button" @click="setMonth(0)">&lt;</div>
            <div class="clndr-next-button" @click="setMonth(1)">&gt;</div>
            <div class="current-month">
              {{ currentDate[0] }}&nbsp;{{ chNumber[currentDate[1]-1] }}月
            </div>
          </div>
          <div class="clndr-grid">
            <div class="days-of-the-week clearfix">
              <div class="header-day" v-for="(d, k) in weeksList" :key="k">
                {{ d }}
              </div>
            </div>
            <div class="days">
              <div v-for="(mon, k) in currentMonthDays" :key="k">
                <!-- calendar-active -->
                <div
                  v-for="(d, i) in mon"
                  @click="changeClassOrder(k, i)"
                  @dblclick="handleManagerAction(k, i)"
                  :key="`${k}${i}`"
                  class="day classNewOrder"
                  :class="{
                    'adjacent-month': k != currentDate[1],
                    'calendar-active':
                      currentDate[1] == k && currentDate[2] == d
                  }"
                >
                  <a
                    class="day-number"
                    :class="{ 'day-today': todays[1] == k && todays[2] == d }"
                    style="cursor: pointer"
                  >
                    {{ d }}
                    <div
                      class="class-center"
                      v-if="studentClassData.length > 0"
                    >
                      <div
                        :class="{
                          'calendar_line class': showClassOrderStatus(i, k, 1)
                        }"
                      ></div>
                      <div
                        :class="{
                          'calendar_line study': showClassOrderStatus(i, k, 2)
                        }"
                      ></div>
                      <div
                        :class="{
                          'calendar_line test_before': showClassOrderStatus(
                            i,
                            k,
                            3
                          )
                        }"
                      ></div>
                      <div
                        :class="{
                          'calendar_line homework': showClassOrderStatus(
                            i,
                            k,
                            4
                          )
                        }"
                      ></div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="event-listing" v-if="isCreateNewOrder">
            <div class="event-listing-title">
              {{ currentDate[1]}}/{{ currentDate[2] }}&nbsp;新建學習規劃
            </div>
            <div class="button_group">
              <div class="button_list">
                <div
                  class="button_type"
                  v-for="manager in managerList"
                  :key="manager.id"
                >
                  <el-button
                    style="width: 130px"
                    type="error"
                    @click="createLeasonList(manager)"
                  >
                    {{ manager.label }}</el-button
                  >
                  <div
                    class="button_child"
                    v-if="newOrderId == manager.id && manager.children"
                  >
                    <div v-for="child in manager.children" :key="child.type">
                      <!-- <div v-if="child.type == 'test'">
                        <el-button
                          v-if="fileList && fileList.length"
                          size="middle"
                          type="success"
                          @click="submitUpload"
                          >點擊上傳
                        </el-button>
                      </div> -->
                      <div>
                        <el-button
                          style="width: 130px"
                          type="warning"
                          @click="createLeasonList(child)"
                        >
                          {{ child.label }}</el-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-listing" v-else>
            <div class="event-listing-title">
              {{ currentDate[1]}}/{{ currentDate[2] }}&nbsp;上課行程
            </div>
            <!-- 1 預約中 2 準備中 3 上課中 4 已下課  5 已取消 6 已完成 -->
            <!-- 教师行事历 -->
            <div v-if="classId == 2" class="event-item">
              <div v-if=" todays[0] == currentDate[0] && todays[1] == currentDate[1] && todays[2] == currentDate[2] " >
                <div v-if="todayClassList">
                  <div
                    class="calendar_detail_container"
                    v-for="(leasson, k) in todayClassList"
                    :key="k"
                  >
                    <div class="calendar_detail_title">
                      {{ leasson.classTitle }}
                    </div>
                    <div>預定時間：{{ leasson.classTime }}</div>
                    <div
                      v-if="
                        leasson.classStatus == '2' || leasson.classStatus == '5'
                      "
                    >
                      預計進度：{{ leasson.expectedProgress }}&nbsp;
                      <span
                        class="class_progress"
                        v-if="leasson.canExpectedProgress"
                        @click="openDialog(1, leasson)"
                        >編寫進度</span
                      >
                    </div>
                    <div>學生：{{ leasson.studentName }}</div>
                    <!-- <div v-if="leasson.classStatus!='5'"> -->
                    <div v-if="leasson.canSignIn">
                      上課：
                      <span
                        class="class_punch_in"
                        @click="openDialog(3, leasson)"
                        >進行打卡</span
                      >
                    </div>
                    <div v-if="!leasson.deleteTag">
                      <div v-if="leasson.canSignOut">
                        下課：
                        <span
                          class="class_punch_out"
                          @click="openDialog(4, leasson)"
                          >進行打卡</span
                        >
                      </div>
                    </div>
                    <div v-if="leasson.teacherSignIn">
                      上課：
                      {{ setTimeVal(leasson.teacherSignInTime) }}完成打卡
                    </div>
                    <div v-if="leasson.teacherSignOut">
                      下課：
                      {{ setTimeVal(leasson.teacherSignOutTime) }}完成打卡
                    </div>
                    <!-- </div> -->
                    <div
                      v-if="leasson.classStatus == '3' && !leasson.canSignIn"
                    >
                      <div v-if="!leasson.teacherSignIn">上課： 未打卡</div>
                    </div>

                    <div
                      v-if="
                        leasson.classStatus == '6' || leasson.classStatus == '4'
                      "
                    >
                      <span v-if="!leasson.teacherSignIn">上課： 未打卡</span>
                      <br />
                      <span v-if="!leasson.canSignOut">
                        <span v-if="!leasson.teacherSignOut">下課： 未打卡</span
                        >&nbsp;
                      </span>
                      <span
                        v-if="leasson.canTeacherReturn"
                        class="class_report"
                        @click="openDialog(2, leasson)"
                        >填寫回報</span
                      >
                      <span v-if="leasson.teacherIsReturn">已回報</span>
                    </div>
                    <!--<div>備註：{{leasson.managerReturnInClass}}</div>-->
                    <div v-if="leasson.deleteTag">
                      上課狀況：{{ leasson.orderRemark }}
                    </div>
                    <div v-else>上課狀況：{{ leasson.orderRemark }}</div>
                    <div
                      class="calendar_detail_state"
                      :class="{
                        state_confirm: leasson.classStatus == '3',
                        state_cancel: leasson.classStatus == '5',
                        state_ready: leasson.classStatus == '2',
                        state_finish:
                          leasson.classStatus == '4' ||
                          leasson.classStatus == '6'
                      }"
                    >
                      {{ rendeStatusString(leasson.classStatus) }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else>
                <div v-if="studentDetail.classOrderData">
                  <div
                    class="calendar_detail_container"
                    v-for="(leasson, k) in studentDetail.classOrderData"
                    :key="k"
                  >
                    <div class="calendar_detail_title">
                      {{ leasson.classTitle }}
                    </div>
                    <div>預定時間：{{ leasson.classTime }}</div>
                    <div
                      v-if="
                        leasson.classStatus == '2' || leasson.classStatus == '5'
                      "
                    >
                      預計進度：{{ leasson.expectedProgress }}&nbsp;
                      <span
                        class="class_progress"
                        v-if="leasson.canExpectedProgress"
                        @click="openDialog(1, leasson)"
                        >編寫進度</span
                      >
                    </div>
                    <div>學生： {{ leasson.studentName }}</div>
                    <div v-if="leasson.classStatus != '5'">
                      <div v-if="leasson.canSignIn">
                        上課：
                        <span
                          class="class_punch_in"
                          @click="openDialog(3, leasson)"
                          >進行打卡</span
                        >
                      </div>
                    </div>
                    <div v-if=" leasson.classStatus == '2' || (leasson.classStatus == '3' && !leasson.canSignIn) " >
                      <div v-if="leasson.teacherSignIn">
                        上課：
                        {{ setTimeVal(leasson.teacherSignInTime) }}完成打卡
                      </div>
                      <div v-else>上課： 未打卡</div>
                    </div>

                    <div v-if="!leasson.deleteTag">
                      <div v-if="leasson.canSignOut">
                        下課：
                        <span
                          class="class_punch_out"
                          @click="openDialog(4, leasson)"
                          >進行打卡</span
                        >
                      </div>
                    </div>
                    <div
                      v-if="
                        leasson.classStatus == '6' || leasson.classStatus == '4'
                      "
                    >
                      <span v-if="leasson.teacherSignIn"
                        >上課：
                        {{
                          setTimeVal(leasson.teacherSignInTime)
                        }}完成打卡</span
                      >
                      <span v-else>上課： 未打卡</span>
                      <br />
                      <span v-if="!leasson.canSignOut">
                        <span v-if="leasson.teacherSignOut"
                          >下課：
                          {{
                            setTimeVal(leasson.teacherSignOutTime)
                          }}完成打卡</span
                        >
                        <span v-else>下課： 未打卡</span>&nbsp;
                      </span>
                      <span
                        v-if="leasson.canTeacherReturn"
                        class="class_report"
                        @click="openDialog(2, leasson)"
                        >填寫回報</span
                      >
                      <span v-if="leasson.teacherIsReturn">已回報</span>
                    </div>
                    <!--<div>備註：{{leasson.managerReturnInClass}}</div>-->
                    <div v-if="leasson.deleteTag">
                      上課狀況：{{ leasson.orderRemark }}
                    </div>
                    <div v-else>上課狀況：{{ leasson.orderRemark }}</div>
                    <div
                      class="calendar_detail_state"
                      :class="{
                        state_confirm: leasson.classStatus == '3',
                        state_cancel: leasson.classStatus == '5',
                        state_ready: leasson.classStatus == '2',
                        state_finish:
                          leasson.classStatus == '4' ||
                          leasson.classStatus == '6',
                      }"
                    >
                      {{ rendeStatusString(leasson.classStatus) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--  学生行事历 -->
            <div v-if="classId == 1 || classId == 3" class="event-item">
              <div
                :class="{
                  'calendar_detail_container learn_plan': containerDetail(
                    leason
                  ),
                }"
                v-for="(leason, index) in currentLearnList"
                :key="leason.learnPlanId"
              >
                <div v-if="leason.classOrderData">
                  <!-- <div
                    class="calendar_detail_container"
                    v-for="(d, k) in leason.classOrderData"
                    :key="k"
                  > -->
                  <div class="calendar_detail_title">
                    {{ leason.classOrderData.classTitle }}
                  </div>
                  <div>預定時間：{{ leason.classOrderData.classTime }}</div>
                  <div>
                    預計進度：{{ leason.classOrderData.expectedProgress }}
                  </div>
                  <div>老師/代碼：{{ leason.classOrderData.teacherInfo }}</div>
                  <!-- <div v-if="leason.classOrderData.haveAcademic">
                    作業内容：
                    {{ leason.classOrderData.sendAcademic }}
                  </div> -->
                  <!-- v-if="leason.classOrderData.classStatus == '4'" -->
                  <div>
                    <span
                      class="calendar_class_infor"
                      @click="openPublicDialog(1, leason.classOrderData)"
                      >上課詳細資訊</span
                    >
                    <!-- &nbsp;&nbsp;
                      <span
                        class="calendar_class_star"
                        @click="openDialog(2, d)"
                        >給予評分</span
                      > -->
                  </div>
                  <div v-if="leason.classOrderData.deleteTag">
                    上課狀況：{{ leason.classOrderData.orderRemark }}
                  </div>
                  <div v-else>
                    上課狀況：{{ leason.classOrderData.orderRemark }}
                  </div>
                  <div
                    class="calendar_detail_state"
                    :class="{
                      state_confirm: leason.classOrderData.classStatus == '3',
                      state_cancel: leason.classOrderData.classStatus == '5',
                      state_ready: leason.classOrderData.classStatus == '2',
                      state_finish:
                        leason.classOrderData.classStatus == '4' ||
                        leason.classOrderData.classStatus == '6',
                    }"
                  >
                    {{ rendeStatusString(leason.classOrderData.classStatus) }}
                  </div>
                  <!-- </div> -->
                </div>
                <div v-if="leason.readingPlanData">
                  <div class="calendar_detail_title read_plan">
                    課前預習
                    <span style="float: right">
                      <el-button 
                      type="warning"
                      size="small"
                      style="margin: 0 20px"
                        @click="
                          deleteReading(leason.learnPlanId, index)
                        "
                        v-if="classId == 3"
                        >刪除</el-button
                      ><el-button type="primary"
                      size="small"
                        @click="
                          editReading(leason.learnPlanId, leason.readingPlanData)
                        "
                        v-if="classId == 3"
                        >編輯</el-button
                      >
                    </span>
                  </div>
                  <div>閲讀題目：{{ leason.readingPlanData.readingTitle }}</div>
                  <div>
                    閲讀内容：<span
                      v-html="leason.readingPlanData.readingContent"
                    ></span>
                  </div>
                  <div>
                    <span
                      >閲讀日期：{{ leason.readingPlanData.readingDate }}</span
                    >
                    &nbsp;&nbsp;
                    <span class="calendar_class_star">
                      <a
                        :href="leason.readingPlanData.readingUrl"
                        target="_blank"
                        >查看資料</a
                      >
                    </span>
                  </div>
                </div>
                <div v-if="leason.beforeTestDate">
                  <div class="calendar_detail_title read_plan">課前測驗</div>
                  <div>測試類型：{{ leason.beforeTestDate.testType }}</div>
                  <div>
                    題目數量：{{ leason.beforeTestDate.questionAmount }}
                    &nbsp;&nbsp;
                    <span class="calendar_class_star">
                      <a
                        @click="enterTesrPaper(leason.beforeTestDate, 'before')"
                      >
                        前往測驗
                      </a>
                    </span>
                  </div>
                  <div v-if="leason.beforeTestDate.useTestId">
                    测试成绩：{{ leason.beforeTestDate.score }}
                  </div>
                </div>
                <div v-if="leason.afterTestDate">
                  <div class="calendar_detail_title read_plan">課后測驗</div>

                  <div>測試類型：{{ leason.afterTestDate.testType }}</div>
                  <div>
                    題目數量：{{ leason.afterTestDate.questionAmount }}
                    &nbsp;&nbsp;
                    <span class="calendar_class_star">
                      <a @click="enterTesrPaper(leason.afterTestDate, 'after')"
                        >前往測驗</a
                      >
                    </span>
                  </div>
                  <div v-if="leason.afterTestDate.useTestId">
                    測試成績：{{ leason.afterTestDate.score }}
                  </div>
                </div>
                <div v-if="leason.homeWorkDate">
                  <div class="calendar_detail_title read_plan">課後作業</div>

                  <div>作業題目：{{ leason.homeWorkDate.homeworkTitle }}</div>
                  <div class="btnGrop">
                    <el-button style="height: 40px;" type="primary" plain @click="openHomeworkList('subject', leason.homeWorkDate.homeworkSubject)">下載課後作業</el-button>
                    <el-button style="height: 40px;" type="success" plain @click="openHomeworkList('answer', leason.homeWorkDate.homeworkAnswer)">下載作業答題</el-button>
                    <div v-if="classId == 1">
                      <el-upload
                        name="fileData"
                        :headers="{ 'Content-Type': 'multipart/form-data' }"
                        :data="{ learnPlanId: leason.homeWorkDate.learnPlanId }"
                        :action="uploadUrl"
                        class="upload-demo"
                        :limit="10"
                        multiple
                        :on-success="submitSuccess"
                        :http-request="httpRequest"
                        :with-credentials="true"
                        :on-change="handleChangeFile"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :before-remove="beforeRemove"
                        :on-exceed="handleExceed"
                        :file-list="fileList"
                      >
                        <!-- multiple -->
                        <el-button> 上傳作業答題 </el-button></el-upload
                      >
                    </div>
                  </div>
                  <div>作業成績：{{ leason.homeWorkDate.score ? leason.homeWorkDate.score : '--'}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>  
    <!-- ... pup視窗-上課詳細資訊 ... -->
    <div class="member_pup_class_detail" v-if="publicDialogId == 1">
      <div class="member_pup_bg" @click="publicDialogId = 0"></div>
      <div class="member_pup_container">
        <div class="member_pup_title">上課詳細資訊</div>
        <div class="member_pup_main">
          <div class="pup_class_detail_main">
            <div class="pup_class_detail_text">
              <div class="title">課程名稱：</div>
              <div class="infor">{{ classDetailList.classTitle }}</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課時間：</div>
              <div class="infor">
                {{ classDetailList.classDate }}&nbsp;{{
                  classDetailList.classTime
                }}&nbsp;合計{{ classDetailList.classHour }}小時
              </div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課老師：</div>
              <div class="infor">{{ classDetailList.teacherInfo }}</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課打卡：</div>
              <div class="infor">
                {{ toHours(classDetailList.teacherSignInTime) }}
              </div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">下課打卡：</div>
              <div class="infor">
                {{ toHours(classDetailList.teacherSignOutTime) }}
              </div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">本次扣點：</div>
              <div class="infor">{{ classDetailList.unitPrice }}點</div>
            </div>
            <div class="pup_class_detail_text">
              <div class="title">上課回報：</div>
              <div class="infor">{{ classDetailList.returnInClass }}</div>
            </div>
          </div>
        </div>
        <img
          alt
          class="member_pup_close"
          @click="publicDialogId = 0"
          src="../../assets/image/close_icon.png"
        />
      </div>
    </div>
    <!-- ... pup視窗-上課詳細資訊end ... -->
  </div>
  <!-- 我的課表end -->
</template>
<script>
import {
  getNowServerTime,
  getTeaClassByDay,
  //  getClassByDay,
  getLearnPlanByDay,
  getUserBeforeTest,
  getStuAfterTest,
  getDeleteReading,
} from "../../api/api";
import { Message } from "element-ui";
import { hsdApi } from "@/api/baseApi";
export default {
  props: ["studentClassData", "studentDetail", "classId"],
  data() {
    return {
      publicDialogId: 0,
      classDetailList: {},
      fileList: [],
      uploadedList: [], //已上傳文件
      learnPlanId: "",
      uploadData: { learnPlanId: this.learnPlanId },
      newOrderId: null, //课程规划
      isCreateNewOrder: false, //是否新建
      webTimeLoop: 5, //定期调接口时长
      timeIntervalCall: null,
      messageTableId: 3,
      currentDate: [],
      todays: [],
      todaysDate: "",
      currentMonthDays: {}, //当前月的天数
      todayClassList: [], //当天上课列表
      currentLearnList: [], //当前展示学习列表
      weeksList: ["日", "一", "二", "三", "四", "五", "六"],
      chNumber: [
        "一",
        "二",
        "三",
        "四",
        "五",
        "六",
        "七",
        "八",
        "九",
        "十",
        "十一",
        "十二",
      ],
      managerList: [
        { id: "study", label: "課前預習" },
        { id: "testBefore", label: "課前測驗" },
        { id: "class", label: "一對一輔導" },
        {
          id: "jobs",
          label: "課後作業",
          children: [
            {
              type: "upload",
              label: "上傳作業",
            },
            // {
            //   type: "down",
            //   label: "下載作業",
            // },
            { type: "test", label: "課後測驗" },
          ],
        },
      ],
      baseApi: "",
      uploadUrl: "",
    };
  },
  watch: {
    studentClassData(nV) {
      console.log(nV);
    },
    studentDetail(nV) {
      console.log(nV);
      if (this.checkIsToday() && !this.classId == 3) {
        this.currentLearnList = this.todayClassList;
      } else {
        this.currentLearnList = nV;
      }
    },
  },
  created() {
    this.baseApi = hsdApi;
    this.uploadUrl = `${this.baseApi}/api/v1/user/upload_answer`;
    let date = new Date();
    let y = date.getFullYear(),
      intM = date.getMonth(),
      d = date.getDate(),
      m = intM + 1,
      mon = m < 10 ? `0${m}` : m,
      day = d < 10 ? `0${d}` : d;
    this.todaysDate = `${y}-${mon}-${day}`; //调接口专用格式
    this.currentDate = [y, m, d];
    this.todays = [y, m, d];
    this.setCurrent(y, m, d - 1);
    this.classId !== 3 && this.getTeaClassByDayList();
    this.webTimeLoop = this.classId == 1 ? 10 : 5;
  },
  methods: {
    //上課詳細資訊
    openPublicDialog(id,data){console.log(id,data)
      this.publicDialogId = id
      this.classDetailList = data
    },
    toHours(t) {
      if (t) {
        let d = new Date(t);
        return (
          d.toLocaleDateString() +
          " " +
          d.getHours() +
          ":" +
          d.getMinutes() +
          ":" +
          d.getSeconds()
        );
      }
      return "";
    },
    //進入測試頁面
    async enterTesrPaper(data, type) {
      const { learnPlanId, testPaperId } = data;
      let res = {};
      if (type == "after") {
        res = await getStuAfterTest({ learnPlanId, testPaperId });
      } else {
        res = await getUserBeforeTest({ learnPlanId, testPaperId });
      }
      if (res && res.success == 1) {
        localStorage.setItem("test_paper_id", res.data.test_paper_id);
        localStorage.setItem("user_test_id", res.data.user_test_id);
        location.href = "/startTest/index.html";
      }
    },
    openHomeworkList(id, homeworkList){console.log(homeworkList,id)
      this.$emit("showDialog",  id == 'subject' ? 6 : 7, homeworkList);
    },
    //上傳作業
    submitUpload(learnPlanId) {
      this.$emit("submitLoad", { fileList: this.fileList, learnPlanId });
    },
    httpRequest(param) {
      let fileObj = Object.assign({ fileData: this.fileList }, param.data)
      this.$emit("submitLoad", fileObj);
      this.submitSuccess();
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
    },
    handlePreview(file) {},
    handleChangeFile(file) {
      this.fileList.push(file);
    },
    submitSuccess(response, file, fileList) {
      this.fileList = [];
      if (response){
         alert(response);
      }
      if (file){
        alert(file);
      }
      if (fileList){
        alert(fileList);
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `當前限制選擇1個文件，本次選擇了${files.length} 個文件,共選擇了${files.length + fileList.length} 個文件`
      );
    },
    beforeRemove(file, fileList) {
      if (file){
        alert(file);
      }
      if (fileList){
        alert(fileList);
      }
      // return this.$confirm(`确定移除 ${file.name}？`);
    },
    //双击课程管理
    handleManagerAction(m, d) {
      if (this.classId == 3) {
        this.isCreateNewOrder = true;
        this.showClassDetail(m, d);
      } else {
        this.isCreateNewOrder = false;
      }
    },
    //单击课程管理
    changeClassOrder(m, d) {
      this.isCreateNewOrder = false;
      this.showClassDetail(m, d);
    },
    //學習規劃
    createLeasonList(m) {
      let id = m.id,
        type = m.type;
      this.newOrderId = id;
      if (this.newOrderId == "study") {
        this.$emit("showDialog", 1, this.currentDate);
      } else if (this.newOrderId == "testBefore") {
        this.$emit("enterCreateTest", "before");
      } else if (this.newOrderId == "class") {
        this.$emit("showDialog", 4);
      } else {
        this.newOrderId = "jobs";
        if (type == "test") {
          this.$emit("enterCreateTest", "after");
        } else {
          this.$emit("uploadOrdownLoad", type);
        }
      }
    },
    //获取服务器时间
    async getServerLocal() {
      const _this = this;
      let res = await getNowServerTime();
      if (res.success == 1) {
        let currentMinute = new Date(res.data).getMinutes();
        let miniteFive =
            currentMinute + this.webTimeLoop - parseInt(currentMinute % 5),
          latestFiveDateInterval = new Date(
            `${new Date(res.data).toLocaleDateString()} ${new Date(
              res.data
            ).getHours()}:${miniteFive}:03`
          ).getTime();
        let firstTimeInterval =
          latestFiveDateInterval - new Date(res.data).getTime();
        setTimeout(() => {
          _this.getTeaClassByDayList();
          this.timeIntervalCall = setInterval(() => {
            _this.getTeaClassByDayList();
          }, 5 * 60 * 1000);
        }, firstTimeInterval);
      }
    },
    //查看教师单日课程列表
    async getTeaClassByDayList() {
      let res = {};
      if (this.classId == 2) {
        res = await getTeaClassByDay({
          scheduleDate: this.todaysDate,
          // scheduleDate: '2020-05-30',
        });
      } else {
        res = await getLearnPlanByDay({
          scheduleDate: this.todaysDate,
        });
      }
      if (res.success == 1) {
        if (this.classId == 1) {
          this.todayClassList =
            res.data && res.data.learnPlanList ? res.data.learnPlanList : [];
          if (this.checkIsToday()) {
            this.currentLearnList = this.todayClassList;
          }
        } else {
          this.todayClassList = res.data;
        }
      }
    },
    checkIsToday() {
      if (this.todays[0] == this.currentDate[0] && this.todays[1] == this.currentDate[1] && this.todays[2] == this.currentDate[2]) {
        return true;
      } else {
        return false;
      }
    },
    containerDetail(leason) {
      if (leason) {
        let keyData = Object.keys(leason),
          vaildData = keyData.filter((d) => d.includes("Dat"));
        for (let k of vaildData) {
          if (leason[k]) return true;
        }
      }
      return false;
    },
    //课程日历点击查看或新建
    showClassDetail(mon, d) {
      let month = parseInt(mon);
      if (month !== this.currentDate[1]) {
        let id = 1,
          dt = d + 1;
        if (month < this.currentDate[1]) {
          id = 0;
          dt = parseInt(this.currentMonthDays[month][d]) - 1; //当前月的天数
        }
        this.setMonth(id, dt);
        // this.getClassOrderList();
      } else {
        this.$set(this.currentDate, 2, d + 1);
        this.currentDate[2] = d + 1;
        if (this.isCreateNewOrder) {
          this.$emit("initPlanOnManager", this.currentDate);
        } else {
          this.$emit("setClassDetailData", d);
        }
        // this.studentDetail = this.studentClassData[d];
      }
    },
    setCurrent(y, m, d) {
      this.currentMonthDays = {};
      let days = new Date(y, m, 0).getDate();
      this.currentMonthDays[m] = days;
      if (m>1){
        let firstDays = new Date(y, m-1, 1).getDay();
        firstDays = firstDays == 0 ? 7 : firstDays;
        let lastMonthDays = new Date(y, m-1, 0).getDate();
        let last = [];
        for (let i = 0; i < firstDays; i++) {
          last.push(lastMonthDays - i);
        }
        this.currentMonthDays[m - 1] = last.reverse();
        this.currentMonthDays[m + 1] = 42 - days - firstDays;
        this.$emit("getClassOrder", y, m, d);
      }else{
        let firstDays = new Date(y-1, 12, 1).getDay();
        firstDays = firstDays == 0 ? 7 : firstDays;
        let lastMonthDays = new Date(y-1, 12, 0).getDate();
        let last = [];
        for (let i = 0; i < firstDays; i++) {
          last.push(lastMonthDays - i);
        }
        this.currentMonthDays[m - 1] = last.reverse();
        this.currentMonthDays[m + 1] = 42 - days - firstDays;
        this.$emit("getClassOrder", y, m, d);
      }
    },
    //编写预计季度
    openDialog(id, order) {
      this.$emit("setTeaOrder", order);
      this.$emit("showDialog", id);
    },
    //发送预计进度
    saveProgress() {
      this.progressIsshow = false;
    },
    //编写上课回报
    writeReport() {
      this.editReport = true;
    },
    //上一个月，下一个
    setMonth(id, day = 0) {
      let thisMonth = this.currentDate[1], y = this.currentDate[0], today = day + 1,newMonth = thisMonth;
      if (id === 0) {
        newMonth = thisMonth - 1;
      } else {
        newMonth = thisMonth + 1;
      }
      if (newMonth === 0) {
        //上一年十二月（y-1，11）
        this.$set(this.currentDate, 0, y - 1);
        this.$set(this.currentDate, 1, 12);
        this.currentDate[0] = y - 1;
        this.currentDate[1] = 12;
      } else if (newMonth === 13) {
        //下一年一月（y+1,0)
        this.$set(this.currentDate, 0, y + 1);
        this.$set(this.currentDate, 1, 1);
        this.currentDate[0] = y + 1;
        this.currentDate[1] = 1;
      } else {
        //当年一月到十二月 （0~11）
        this.$set(this.currentDate, 0, y);
        this.$set(this.currentDate, 1, newMonth);
        this.currentDate[0] = y;
        this.currentDate[1] = newMonth;
      }
      this.$set(this.currentDate, 2, today);
      this.currentDate[2] = today;
      this.setCurrent(this.currentDate[0], this.currentDate[1], today);
    },
    //打卡時間過濾
    setTimeVal(dt) {
      let date = new Date(dt);
      let h = date.getHours(),
        m = date.getMinutes(),
        s = date.getSeconds();
      m = m < 10 ? "0" + m : m;
      return date.toLocaleDateString() + " " + h + ":" + m + ":" + s;
    },
    // 判断当天课程状态
    showClassOrderStatus(i, k, type) {
      let data = this.studentClassData[i],
        isDate = this.currentDate[1] == k;
      if (this.classId == 2) {
        if (type == 1) {
          let classOrder = data.classOrderData;
          return classOrder && isDate;
        } else {
          return false;
        }
      } else {
        if (type == 1) {
          if (data && data.length) {
            let classOrder = data.filter((item) => item.classOrderData);
            let isOrder = classOrder && classOrder.length;
            return isOrder && isDate;
          }
        } else if (type == 2) {
          if (data && data.length) {
            let classOrder = data.filter((item) => item.readingPlanData);
            let isOrder = classOrder && classOrder.length;
            return isOrder && isDate;
          }
        } else if (type == 3) {
          if (data && data.length) {
            let classOrder = data.filter((item) => item.beforeTestDate);
            let isOrder = classOrder && classOrder.length;
            return isOrder && isDate;
          }
        } else {
          if (data && data.length) {
            let classOrder = data.filter((item) => item.homeWorkDate),
              testOrder = data.filter((item) => item.afterTestDate);
            let isOrder =
              (classOrder && classOrder.length) ||
              (testOrder && testOrder.length);
            return isOrder && isDate;
          }
        }
      }
      return false;
    },
    //上课状态中文
    rendeStatusString(id) {
      let strL = ["預約中", "準備中", "上課中", "已下課", "已取消", "已完成"];
      return strL[id - 1];
    },
    // setTodayData(list) {
    //   this.todayClassList = list;
    // },
    async deleteReading(learnPlanId, index) {
      const res = await getDeleteReading({learnPlanId});
      if (res && res.success == 1) {
        this.$set(this.currentLearnList, index, {
          ...this.currentLearnList[index],
          readingPlanData: null,
        });
      }
    },
    //編輯課前預習
    async editReading(learnPlanId, readForm){
      this.$emit("editReadForm", { learnPlanId, readForm })
    }
  },
  mounted() {
    this.classId !== 3 && this.getServerLocal();
  },
  destroyed() {
    if (this.timeIntervalCall) {
      clearInterval(this.timeIntervalCall);
      this.timeIntervalCall = null;
    }
  },
};
</script>

<style>
@import "../../assets/css/member_center.css";
@import "../../assets/css/clndr.css";
</style>
<style scoped>
.classNewOrder {
  height: 70px !important;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}
.class-center {
  width: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 6px auto;
}
.class-study {
  border-bottom: 4px solid #0ba96c;
}
.class-read {
  border-bottom: 4px solid #202020;
}
.calendar_line {
  width: 20px;
  height: 4px;
  margin-top: 2px;
}
.greyBg {
  color: #757575;
}
.button_group {
  position: relative;
  padding: 24px 24px 100px 24px;
}
.button_list {
  margin: 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.button_type {
  padding-bottom: 12px;
}
.button_child {
  margin: 12px 0;
}
.button_child .el-button {
  margin-bottom: 10px;
}
.calendar_tips {
  width: 20px;
  height: 4px;
  margin: 5px 5px 5px 20px;
  display: inline-block;
}
.study {
  background: #232323;
}
.test_before {
  background: #23b4f9;
}
.class {
  background: #018247;
}
.homework {
  background: #ff0000;
}
.learn_plan {
  padding: 12px 0;
  border-bottom: 1px solid #bfbfbf;
}
.read_plan {
  /* margin-top: 10px; */
  font-weight: 800;
  padding: 10px 0px;
}
.btnGrop {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.btnGrop button{
  margin: 10px 0;
}
</style>
